
<style lang="css" scoped>
.container {
  background: #fff;
}
.desc_text {
  font-size: 11px;
  font-style: italic;
  padding: 4px 0px;
  margin: 0px;
}

p {
  margin-bottom: 0.5rem;
}
hr {
  color: #000;
  border-bottom: 1px solid #000;
  margin: 10px auto;
}

table tr td {
  padding: 8px 10px;
}
</style>

<template>
  <div class="container" v-if="billing">
    <div class="row p-5">
      <div class="col-lg-12">
        <div class="invoice-title">
          <div class="row">
            <div class="col-8">
              <h1 style="font-size: 14px; font-family: fantasy;">
                HU THUI - Inc
              </h1>
            
              <p class="desc_text">
                Website: <a href="huthui.com" target="_blank">huthui.com</a>
              </p>
           
            </div>

            <div class="col-4 text-sm-end">
              <img
                src="@/assets/images/logo-dark.png"
                alt="logo"
                height="60"
              />
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col">
            <h4 class="font-size-16" style="color: red">
              Invoice #{{ billing.invoice }}
            </h4>
          </div>
          <div class="col text-sm-end">
            {{ formatDate(billing.date) }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <address class="">
              <p class="mb-1"><strong>Sender's name</strong></p>
              <p>{{ billing.sender_fn }}</p>
              <p class="mb-1"><strong>Sender's phone number</strong></p>
              <p>{{ billing.sender_phone }}</p>
             
              <p class="my-1"><strong>Sender's address </strong></p>
              <p>{{ billing.sender_address }}</p>
            </address>
          </div>
          <div class="col-6 text-sm-end">
            <address class="">
              <p class="mb-1"><strong>Recipient's name</strong></p>
              <p>{{ billing.recipient_fn }}</p>
              <p class="mb-1"><strong>Recipient's phone number</strong></p>
              <p>{{ billing.recipient_phone }}</p>
              <p class="mb-1" v-if="billing.recipient_email">
                <strong>Recipient's e-mail</strong>
              </p>
              <p v-if="billing.recipient_email">
                {{ billing.recipient_email }}
              </p>
              <p class="my-1"><strong>Recipient's address </strong></p>
              <p>{{ billing.recipient_address }}</p>
            </address>
          </div>
        </div>
        <hr />

        <usvn :billing="billing" v-if="billing.type == 1"></usvn>
        <vnus :billing="billing" v-if="billing.type == 2"></vnus>
        <usus :billing="billing" v-if="billing.type == 3"></usus>
        <seaway :billing="billing" v-if="billing.type == 4"></seaway>
        <money :billing="billing" v-if="billing.type == 5"></money>
        <buystore :billing="billing" v-if="billing.type == 6"></buystore>
        <shopping :billing="billing" v-if="billing.type == 7"></shopping>

        <!-- buystore -->
        <div class="row text-center">
          <div class="col text-center h6">
            <strong>
              Thank you very much for trusting our service! See you
              later!</strong
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usvn from "@/components/billing/views/usvn.vue";
import vnus from "@/components/billing/views/vnus.vue";
import usus from "@/components/billing/views/usus.vue";
import seaway from "@/components/billing/views/seaway.vue";
import money from "@/components/billing/views/money.vue";
import buystore from "@/components/billing/views/buystore.vue";
import shopping from "@/components/billing/views/shopping.vue";
// buystore
import axios from "axios";
import moment from 'moment-timezone';
export default {
  components: {
    usvn,
    vnus,
    usus,
    seaway,
    money,
    buystore,
    shopping
  },
  data() {
    return {
      ListPrice: null,
      billing: null,
    };
  },
  methods:{
     formatDate(_data) {
       return moment.tz(_data, 'UTC').tz("America/Los_Angeles").format('DD-MM-YYYY HH:mm');
    },
  },
  created() {
    axios({
      url:
        process.env.VUE_APP_SERVER_URL +
        "/api/bill/info/" +
        this.$route.query.id,
      method: "get",
    })
      .then(({ data }) => {
        this.billing = data;
        return data;
      })
      .catch((error) => {
        error && error.message && error.response.data
          ? this.$toasted.error(
              error.response ? error.response.data : error.message
            )
          : this.$toasted.error("Lỗi không xác định xảy ra.");
      });

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/example-update",
      method: "get",
    })
      .then(({ data }) => {
        this.ListPrice = data;
        return data;
      })
      .catch((error) => {
        error && error.message && error.response.data
          ? this.$toasted.error(
              error.response ? error.response.data : error.message
            )
          : this.$toasted.error("Lỗi không xác định xảy ra.");
      });
  },

  updated() {
    document.querySelectorAll("table").forEach((item) => {
      item.setAttribute("class", "table table-bordered");
    });
  },
};
</script>