<template>
  <div v-if="billing">
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">
          Commodity Description
        </h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th width="50%">Item Name</th>
              <th width="50%" class="text-sm-end">Total Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in billing.product" :key="index">
              <td>{{ item.name }}</td>
              <td class="text-sm-end">{{ item.lb }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12"></div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">Infomation</h3>
      </div>
      <div
        class="col-12 mt-3 editor-container"
        v-if="ListPrice"
        v-html="ListPrice.txt"
      ></div>
    </div>
    <hr />

    <div class="p-2">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Order details</h3>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th colspan="2">BuyStore Service</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p>Total order</p>
          </td>
            <td width="50%" class="text-sm-end">
            <p>{{ billing.buystore.buy_lb }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Fee Rate</p>
          </td>
           <td width="50%" class="text-sm-end">
            <p>
              {{ billing.buystore.price_buy }}
            </p>
          </td>
        </tr>

        <tr>
          <td>
            <p>Fee</p>
            <p>{{ billing.buystore.subtotal_buy }}</p>
          </td>
          <td width="50%" class="text-sm-end">
            <p>{{ billing.buystore.subtotal_buy }}</p>
          </td>
        </tr>

        <tr>
          <td>
            <p>Discount (-)</p>
          </td>
           <td width="50%" class="text-sm-end">
            <p>{{ billing.buystore.disc_count_buy }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Total service change (=)</p>
          </td>
          <td width="50%" class="text-sm-end">
            <p>{{ billing.buystore.total_buy }}</p>
          </td>
        </tr>

        <tr>
          <td colspan="2" class="text-center">
            <b>Total : {{ billing.buystore.total }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ListPrice: null,
    };
  },
  created() {
    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/example-update",
      method: "get",
    })
      .then(({ data }) => {
        this.ListPrice = data;
        return data;
      })
      .catch((error) => {
        error && error.message && error.response.data
          ? this.$toasted.error(
              error.response ? error.response.data : error.message
            )
          : this.$toasted.error("Lỗi không xác định xảy ra.");
      });
  },
  updated() {
    document.querySelectorAll("table").forEach((item) => {
      item.setAttribute("class", "table table-bordered");
    });
  },
};
</script>