<template>
  <div v-if="billing">
      <!-- <pre>{{billing}}</pre> -->
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">
          Commodity Description
        </h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th width="50%">Item</th>
              <th width="50%" class="text-sm-end">Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in billing.product" :key="index">
              <td>{{ item.name }}</td>
              <td class="text-sm-end">{{ item.lb }} lb</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12"></div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">Infomation</h3>
      </div>
      <div
        class="col-12 mt-3 editor-container"
        v-if="ListPrice"
        v-html="ListPrice.txt"
      ></div>
    </div>
    <hr />
    <div class="p-2">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Order details</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
        
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">
              {{ billing.service_name }}
              {{
                billing.service_opition
                  ? "[" + billing.service_opition + "]"
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>Total weight</td>
            <td class="text-sm-end">{{ billing.total_weight }} lb</td>
          </tr>
          <tr>
            <td>Fee rate</td>
            <td class="text-sm-end">{{ billing.shipping_fee }}</td>
          </tr>
          
          <tr>
            <td>Subtotal</td>
            <td class="text-sm-end">{{ billing.subtotal }}</td>
          </tr>
          <tr>
            <td>Discount (-)</td>
            <td class="text-sm-end">{{ billing.discount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row">
      <div class="col text-sm-end">
        <strong> Total : {{ billing.total_changer }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
   props: {
       billing: {
           type: Object,
           default: null
       }
   },
   data() {
    return {
      ListPrice: null
    };
  },
created() {
    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/example-update",
      method: "get",
    })
      .then(({ data }) => {
        this.ListPrice = data;
        return data;
      })
      .catch((error) => {
        error && error.message && error.response.data
          ? this.$toasted.error(
              error.response ? error.response.data : error.message
            )
          : this.$toasted.error("Lỗi không xác định xảy ra.");
      });
  },
    updated() {
    document.querySelectorAll("table").forEach((item) => {
      item.setAttribute("class", "table table-bordered");
    });
  },

}
</script>