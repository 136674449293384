<template>
  <div v-if="billing">
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">
          Commodity Description
        </h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in billing.product" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.lb }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12"></div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <h3 class="h6" style="color: rgb(56, 118, 29)">Infomation</h3>
      </div>
      <div
        class="col-12 mt-3 editor-container"
        v-if="ListPrice"
        v-html="ListPrice.txt"
      ></div>
    </div>
    <hr />
    <div class="p-2">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Order details</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
       
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">
              {{ billing.service_name }}
              {{
                billing.service_opition
                  ? "[" + billing.service_opition + "]"
                  : ""
              }}
            </td>
          </tr>
             <tr>
            <td>Number</td>
            <td class="text-sm-end">{{ billing.total_weight }}</td>
          </tr>

           <tr>
            <td>Price</td>
            <td class="text-sm-end">{{ billing.service_price }}</td>
          </tr>

          <tr>
            <td>Discount (-)</td>
            <td class="text-sm-end">{{formatNumber(billing.discount) }}</td>
          </tr>

          <!-- <tr>
            <td>Total service fee</td>
            <td class="text-sm-end">{{ formatNumber(billing.service_price)  }}</td>
          </tr>
           -->
         
          <tr>
            <td>Tax rate</td>
            <td class="text-sm-end">{{ formatNumber(billing.shipping_fee) + `${billing.fee_unit}` }}</td>
          </tr>
         
           
          <tr>
            <td>Sale tax (+)</td>
            <td class="text-sm-end">{{ formatNumber(billing.total_tax) }} </td>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td class="text-sm-end">{{ formatNumber(billing.subtotal) }}</td>
          </tr>
         
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row">
      <div class="col text-sm-end">
        <strong> Total : {{ formatNumber(billing.total_changer) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ListPrice: null,
    };
  },
  methods:{
    formatNumber(number){
      return new Intl.NumberFormat('en-IN').format(number)
    },
    price(value1,value2){
      return (Number(value1)+Number(value2)).toFixed(2)
    }
  },
  created() {
    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/example-update",
      method: "get",
    })
      .then(({ data }) => {
        this.ListPrice = data;
        return data;
      })
      .catch((error) => {
        error && error.message && error.response.data
          ? this.$toasted.error(
              error.response ? error.response.data : error.message
            )
          : this.$toasted.error("Lỗi không xác định xảy ra.");
      });
  },
    updated() {
    document.querySelectorAll("table").forEach((item) => {
      item.setAttribute("class", "table table-bordered");
    });
  },
};
</script>